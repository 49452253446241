import React from "react"
import { useStaticQuery, graphql, Img, Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query landingPage {
    strapiLandingPageItem {
      item {
        name
        image {
          publicURL
        }
      }
    }
  }
  `)

  return (
    <Layout title={'index'}>
      <div className='content'>
        <div style={{ margin: 'auto' }}>
          <img src={data.strapiLandingPageItem.item.image.publicURL}/>
        </div>
      </div>
      <div className='description'>
        <p style={{fontWeight: 'bold'}}>{data.strapiLandingPageItem.item.name}</p>
        <p> {data.strapiLandingPageItem.item.description} </p>
      </div>
    </Layout>
  )
}

export default IndexPage

